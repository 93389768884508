import mainjson from './main';
import { Level } from '../utils/LoginFunctions';

function checkIf(data) {
    if (parseInt(Level()) <= 0) {
        return true;
    }
    else {
        return false;
    }
}

export default function GetData() {
        let data = {
        "apitype":"dates",
        "id":"date_id",
        "subidname":"date_date",
        "options":["edit","delete","insert"],
        "nameSingle":"date",
        "nameMultiple":"dates",
        "extraoptions":[
            {
                "name":"Orders",
                "page":"/dateorders",
            },
            {
                "name":"Ticket types",
                "page":"/datetickets",
            },
        ],
      
        "fields":{
            "field0":{
                "name":"ID",
                "field":"date_id",
                "type":"Display",
                "edit":false,
                "list":true
            },
            "field1":{
                "name":"Date",
                "field":"date_date",
                "type":"DateTimeInput",
                "required":true,
                "list":true
            },
            "field2":{
                "name":"Time Open",
                "field":"date_open_time",
                "type":"TimeInput",
                "required":true,
                "list":true,
                "linkedField":"date_date"
            },
            "field3":{
                "name":"Sold",
                "field":"sold",
                "type":"Display",
                "list":true,
                "edit":false,   
            }, 
            "field4":{
                "name":"Description",
                "field":"date_description",
                "type":"TextInput",
                "required":false,
                "list":true,
            },
            
        }
    }

    return data;
}